@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;600;900&family=Sometype+Mono:wght@400;500&display=swap");

:root {
  --color-text: #000;
  --color-y-prisma: #7bb6ff;
  --color-cvx-prisma: #ffcd82;
  --color-background: #fff;

  --switch-light: #d8dbe0;
  --switch-dark: #28292c;
}

* {
  box-sizing: border-box;
}

@keyframes rainbowShift {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-360deg);
  }
}

.neon-text span span {
  display: inline-block;
}

html {
  overflow-y: scroll;
}

html,
body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  background-color: var(--color-background);
}

.info-content, .emissions-tooltip{
  box-shadow: 0px 0px 15px var(--color-text);
}


.app-container {
  width: 100%;
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-background);
}

.title-container {
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
}

.neon-text {
  font-size: 3em;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 900;
}

.data-stale-banner {
  background-color: red;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.data-normal-banner {
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.data-normal-banner > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.data-normal-banner > div > img {
  padding-right: 10px;
}


@media only screen and (max-width: 450px) {
  .data-normal-banner > div {
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }
}

@media only screen and (max-width: 720px) {
  .neon-text {
    font-size: 2em;
  }
}

@media only screen and (max-width: 450px) {
  .neon-text {
    font-size: 1.5em;
  }
}

.section-title {
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2em;
}

.chart-container {
  width: 90%;
  border-radius: 5px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .chart-container {
    width: 99%;
  }
}

.chart-title {
  text-align: center;
  font-size: 2em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
  margin-top: 10px;
}

@media only screen and (max-width: 500px) {
  .chart-title {
    font-size: 1.4em;
  }
}

.chart-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.recharts-cartesian-axis-tick-value {
  fill: var(--color-text);
}

.table-container {
  border-radius: 5px;
  width: 90%;
  margin-top: 30px;
}

.table-container.emi {
  margin-top: 0px;
}


table {
  width: 100%;
  text-align: center;
  font-size: 1.4em;
  border-collapse: collapse;
  margin-bottom: 8px;
}

@media only screen and (max-width: 750px) {
  table {
    font-size: 1em;
  }
}

@media only screen and (max-width: 400px) {
  .table-container {
    width: 98%;
    margin-top: 20px;
  }
  .filter200 {
    font-size: 0.9em;
  }
}

table.claim,
th,
td {
  border: none;
}

table.claim thead tr th {
  text-align: center;
  font-size: 2em;
  padding: 10px 10px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  table.claim thead tr th {
    font-size: 1.5em;
  }
}

table.claim tbody tr td:last-child {
}

table.claim tbody tr td {
  padding: 14px 0;
  text-align: left;
}

table.claim tbody tr td:first-child {
  text-align: right;
  padding-right: 20px;
  font-weight: 600;
  word-break: break-word  ;
}

table.claim tbody tr:first-child td:not(:first-child) {
  border-top: solid 1px var(--color-text);
}
table.claim tbody tr td:not(:first-child) {
  text-align: center;
  border-bottom: solid 1px var(--color-text);
  font-size: 1.5em;
}

@media only screen and (max-width: 600px) {
  table.claim tbody tr td:first-child {
    padding-right: 0px;
    font-size: 0.8em;
  }
  table.claim tbody tr td:not(:first-child) {
    font-size: 1.3em;
  }
}

.link-container {
  color: var(--color-text);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.custom-tooltip {
  background-color: var(--color-background);
  padding: 5px 20px;
  font-size: 1.3em;
  box-shadow: 0px 0px 10px var(--color-text);
  border-radius: 6px;
}

.font-weight-bold {
  font-weight: 900;
}

.font-weight-normal {
  font-weight: 100;
}

.countdown-container {
  width: auto;
  display: inline-block;
  margin: 0;
  margin-left: 5px;
}

.countdown-container-text {
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .link-container img {
    width: 50px;
  }
}

.undertable {
  display: flex;
  font-family: 'Sometype Mono', monospace;
  width: 90%;
  font-size: 1em;
  flex-direction: column;
  align-items: flex-end;
  color: var(--color-text);
  font-weight: 100;
  opacity: 0.35;
  margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
  .undertable {
    font-size: 0.85em;
  }
}

@media only screen and (max-width: 400px) {
  .undertable {
    font-size: 0.75em;
  }
}

.dark {
  --color-text: #fff;
  --color-background: #121212;
}

.toggle-switch {
  position: absolute;
  width: 50px;
  right: 10px;
  top: 10px;
}


label.switch {
  position: absolute;
  width: 100%;
  height: 25px;
  background-color: var(--switch-dark);
  border-radius: 50px;
  cursor: pointer;
}

label.switch input {
  position: absolute;
  display: none;
}

.slider {
  position: absolute;
  width: 50px;
  height: 25px;
  border-radius: 50px;
  transition: 0.3s;
}

input:checked ~ .slider {
  background-color: var(--switch-light);
}

.slider::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow: inset 7px -3px 0px 0px var(--switch-light);
  background-color: var(--switch-dark);
  transition: 0.3s;
}

input:checked ~ .slider::before {
  transform: translateX(25px);
  background-color: var(--switch-dark);
  box-shadow: none;
}

.info-mark {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
  margin-left: 5px;
  font-size: 0.5em;
}

.info-content {
  visibility: hidden;
  width: 200px;
  background-color: var(--color-background);
  color: var(--color-text);
  border: solid 1px var(--color-text);
  text-align: left;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1.1em;
}

.info-content.yearn {
  box-shadow: 0 0 10px var(--color-y-prisma), 0 0 15px var(--color-y-prisma);
  border: solid 2px var(--color-y-prisma);
}

.info-content.convex {
  box-shadow: 0 0 10px var(--color-cvx-prisma), 0 0 15px var(--color-cvx-prisma);
  border: solid 2px var(--color-cvx-prisma);
}

.info-content p:first-child {
  margin-top: 0;
}

.info-content p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .info-content {
    width: 200px;
  }
}

.info-mark:hover .info-content {
  visibility: visible;
  opacity: 1;
}

.info-mark:hover .info-content {
  visibility: visible;
  opacity: 1;
}

td:hover .info-content {
  visibility: visible;
  opacity: 1;
}



.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  padding: 0 5px;
  align-items: center;
  z-index: 1;
  height: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 -10px 10px var(--color-background);
}

.footer-tabs {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 100%;
  font-size: 1.1em;
}

.footer-tab {
  width: 25%;
  cursor: pointer;
  padding: 5px 10px;
  font-weight: normal;
  border: 1px solid var(--color-text);
  border-radius: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  margin: 0 5px;
  min-width: 60px;
}

.footer-tab.active {
  font-weight: bold;
  box-shadow: none;
}

.footer-tab:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 500px) {
  .footer-tab {
    padding: 0 20px;
    margin: 0 2px;
  }
  .footer-tabs {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 350px) {
  .footer-tab {
    padding: 0 2px;
    margin: 0;
  }
}


.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal {
  background-color: var(--color-background);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 3;
  overflow-y: auto;
  text-align: right;
}


.modal p {
  opacity: 0.6;
}

.modal table.emissions {
  margin-top: 0;
}

.modal table.emissions th {
  padding: 10px;
}

@media only screen and (max-width: 800px) {
  .modal p {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 500px) {
  .modal {
    width: 80vw;
  }
  .modal p {
    font-size: 0.7em;
  }
}


.custom-tooltip p {
  font-weight: normal;
}

.emissions-tooltip {
  visibility: hidden;
  width: 280px;
  background-color: var(--color-background);
  color: var(--color-text);
  border: solid 1px var(--color-text);
  text-align: left;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 10;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8em;
  font-family: "Red Hat Display", sans-serif;
  text-shadow: none;
}

.emissions-tooltip p:last-child {
  margin-bottom: 0;
}

.emissions-tooltip.red {
  box-shadow: 0px 0px 15px rgb(255, 122, 122);
  border: solid 2px rgb(255, 122, 122);
}

.emissions-tooltip.blue {
  box-shadow: 0px 0px 15px rgb(128, 128, 255);
  border: solid 2px rgb(128, 128, 255);

}

.emissions-tooltip.green {
  box-shadow: 0px 0px 15px rgb(126, 255, 126);
  border: solid 2px rgb(126, 255, 126);

}

.emissions-tooltip.pink {
  box-shadow: 0px 0px 15px rgb(228, 121, 255);
  border: solid 2px rgb(228, 121, 255);

}

.emissions-tooltip.cyan {
  box-shadow: 0px 0px 15px rgb(121, 228, 255);
  border: solid 2px rgb(121, 228, 255);

}

@media only screen and (max-width: 600px) {
  .emissions-tooltip {
    width: 200px
  }
}

.emissions-tooltip.tipone {
  left: 80%;
}
.emissions-tooltip.tiplast {
  left: -30%;
}
.emissions-tooltip.penalty {
  left: -30%;
}

@media only screen and (max-width: 700px) {
  .emissions-tooltip.tipone {
    left: 150%;
  }
  .emissions-tooltip.tiplast {
    left: -60%;
  }

  .emissions-tooltip.penalty {
    left: -60%;
  }
}

.emissions-tooltip-content {
  display: flex;
  flex-direction: column;
}

.emissions-tooltip-visible {
  visibility: visible;
  opacity: 1;
}

.emissions-cell {
  position: relative;
  cursor: pointer;
}

.projected td > span {
  opacity: 0.4;
}

th.emissions-cell .emissions-tooltip  {
  font-weight: 400;
}

.emissions-cell:hover .emissions-tooltip {
  visibility: visible;
  opacity: 1;
}

.emissions-cell:hover {
  text-decoration: underline;
}

.emissions-tooltip:hover {
  visibility: visible;
  opacity: 1;
}

table.emissions {
  text-align: left;
  margin-top: 30px;
  overflow: visible;
}

table.emissions td {
  font-family: 'Sometype Mono', monospace;
  padding: 5px;
}

table.emissions tr:not(:first-child) {
  border-top: solid 1px var(--color-text) ;
}
table.emissions tr:last-child {
  border-bottom: solid 1px var(--color-text) ;
}

table.emissions th {
  padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  table.emissions {
    font-size: 0.8em;
  }
}

.toggle-table {
  background-color: var(--color-background);
  color: var(--color-text);
  border: solid 1px var(--color-text);
  padding: 5px 10px;
  border-radius: 15px;
}

.toggle-table:hover {
  cursor: pointer;
  text-decoration: underline;
}

.projected {
  /* background-color: #00000016; */
  font-style: italic;
}

.undertable-emissions{
  font-family: 'Sometype Mono', monospace;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  color: var(--color-text);
  font-weight: 100;
}

.undertable-emissions p {
  margin: 0;
}

.rainbow-row {
  animation-name: rainbowShift;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rainbow-row td:nth-child(1) {
  text-shadow: 0 0 3px red;
}
.rainbow-row td:nth-child(2) {
  text-shadow: 0 0 3px orange;
}

.current span {
  animation-name: rainbowShift;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.current .emissions-tooltip {
  animation-name: unset;
}

.current td:nth-child(1) {
  text-shadow: 0 0 3px red;
}
.current td:nth-child(2) {
  text-shadow: 0 0 3px orange;
}
.current td:nth-child(3) {
  text-shadow: 0 0 3px yellow;
}
.current td:nth-child(4) {
  text-shadow: 0 0 3px rgb(0, 218, 0);
}
.current td:nth-child(5) {
  text-shadow: 0 0 3px rgb(0, 229, 255);
}
.current td:nth-child(6) {
  text-shadow: 0 0 3px indigo;
}
.current td:nth-child(7) {
  text-shadow: 0 0 3px violet;
}

.italic-disc {
  font-weight: 100;
}


.ascending, .descending {
  cursor: pointer;
}


table.table-boosts {
  font-family: 'Sometype Mono', monospace;
  font-weight: 100;
  text-align: left;
}

table.table-boosts th {
  padding-bottom: 10px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 700;
  
}

table.table-boosts th:not(:first-child) {
  /* text-decoration: underline; */
  cursor: pointer;

}

@media only screen and (max-width: 500px) {
  table.table-boosts {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 400px) {
  table.table-boosts {
    font-size: 0.8em;
  }
}

.modal-background.alt .modal {
  height: 90vh;
  /* width: 90vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-background.alt .modal input {
  padding: 10px;
  border: solid 2px var(--color-text);
  margin-bottom: 20px;
  width: 90%;
}

.modal-background.alt .modal table {
  font-family: 'Sometype Mono', monospace;
  font-weight: 100;
  text-align: left;
}

.modal-background.alt .modal table th {
  font-family: "Red Hat Display", sans-serif;
  font-weight: 700;
  padding-bottom: 10px;
}

.modal-background.alt .modal table th:first-child {
  padding-right: 20px;
}

.modal-background.alt .modal table td:first-child {
  padding-right: 20px;
}

@media only screen and (max-width: 500px) {
  .modal-background.alt .modal table {
    font-size: 0.9em;
  }
  .modal-background.alt .modal {
    width: 94vw;
  }
}

.modal-background.alt h2 {
  margin-top: 0px;
}

@media only screen and (max-width: 700px) {
  .modal-background.alt h2 {
    font-size: 0.9em;
  }
}


.modal-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal-buttons-container button {
  margin: 5px 20px;
  max-width: 300px;
}


.clickable {
  cursor: pointer;
  text-decoration: underline; 
}


.toast {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-background);
  border: solid 1px rgb(0, 218, 0);
  box-shadow: 0 0 10px rgb(0, 218, 0);
  color: var(--color-text);
  padding: 20px 10px;
  border-radius: 5px;
  cursor: pointer;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@media only screen and (max-width: 500px) {
  .toast {
    font-size: 0.7em;
  }
}

@keyframes fadein {
  from { top: 0px; opacity: 0; }
  to { top: 40px; opacity: 1; }
}

@keyframes fadeout {
  from { top: 40px; opacity: 1; }
  to { top: 0px; opacity: 0; }
}

.boosts-disclaimer {
  max-width: 500px;
  text-align: center;
  padding: 20px;
}

@media only screen and (max-width: 500px) {
  .boosts-disclaimer {
    font-size: 0.8em;
  }
}

.boost-tooltip {
  position: absolute;
  background-color: var(--color-background);
  color: var(--color-text);
  border: 1px solid rgb(255, 238, 89);
  padding: 15px;
  border-radius: 5px;
  z-index: 10;
  left: 0; 
  transform: translate(30vw, -105%);
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  box-shadow: 0 0 10px rgb(255, 238, 89);
  font-size: 0.8em;
}

@media only screen and (max-width: 750px) {
  .boost-tooltip {
    font-size: 1em;
  }
}

tr:hover .boost-tooltip {
  visibility: visible;
  opacity: 1;
}

.table-boosts tr:hover td{
  text-decoration: underline;
}

.check-container {
  width: 90%;
  display: flex;
}

@media screen and (max-width: 400px) {
  .check-container {
    width: 98%;
  }
}